import { useAtom, useAtomValue } from 'jotai';
import { useCallback } from 'react';

import { loadingAtom, paidLoadingAtom } from '$src/business/atoms/loading';

export function useLoadingAtom() {
  const [loading, setLoadingAtom] = useAtom(loadingAtom);

  const onLoadingChange = useCallback((nextLoading: boolean) => {
    setLoadingAtom(nextLoading);
  }, []);

  return { loading, onLoadingChange };
}

export function usePaidLoadingAtom() {
  const [paidLoading, setPaidLoadingAtom] = useAtom(paidLoadingAtom);

  const onPaidLoadingChange = useCallback((nextLoading: boolean) => {
    setPaidLoadingAtom(nextLoading);
  }, []);

  return { paidLoading, onPaidLoadingChange };
}
