import { Box } from '@chakra-ui/react';

import { FormLoader } from '$components/FormLoader';
import { PaidLoader } from '$components/PaidLoader';

interface LoadingWrapperProps {
  children: React.ReactNode;
  isLoading: boolean;
  isPaidLoading: boolean;
}

export const LoadingWrapper = ({ isLoading, isPaidLoading, children }: LoadingWrapperProps) => {
  return (
    <>
      <Box
        display={isLoading || isPaidLoading ? 'none' : 'block'}
        overflowY={isLoading || isPaidLoading ? 'hidden' : 'auto'}
        h="full"
      >
        {children}
      </Box>
      {isLoading && <FormLoader />}
      {isPaidLoading && <PaidLoader />}
    </>
  );
};
