import { useMutation, useQueryClient } from '@tanstack/react-query';

import type { Cart } from '@getjust/gateway';

import { CART_QUERY_KEY } from '$src/constants';
import { useBaseApiUrl } from '$src/hooks/client/useBaseApiUrl';
import { gateway } from '$src/http';
import { DeleteLineItemsInput } from '$src/pages/api/[shopId]/[cartId]/delete-line-items';

const MUTATION_CART_DELETE_LINE_ITEMS_KEY = 'MUTATION/REMOVE_CHECKOUT_LINE_ITEMS';

export const useDeleteLineItems = () => {
  const baseUrl = useBaseApiUrl();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (items: DeleteLineItemsInput) => gateway.post<Cart>(`${baseUrl}/delete-line-items`, items),
    mutationKey: [MUTATION_CART_DELETE_LINE_ITEMS_KEY],
    onSuccess: ({ data }) => {
      queryClient.setQueryData<Cart>([CART_QUERY_KEY], data);
    },
  });
};
