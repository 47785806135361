import { z as t } from "zod";
const i = t.object({
  id: t.number(),
  key: t.string(),
  attachment: t.record(t.string())
}), r = t.object({
  dataRecordsEnabled: t.boolean(),
  description: t.string(),
  enabled: t.boolean(),
  id: t.number(),
  key: t.string(),
  updatedAt: t.string(),
  variants: i.array().optional()
}), l = t.object({
  flagID: t.number(),
  flagKey: t.string(),
  flagSnapshotID: t.number(),
  segmentID: t.number().optional(),
  timestamp: t.string(),
  variantAttachment: t.record(t.string()).optional(),
  variantID: t.number().optional(),
  variantKey: t.string().optional()
}), s = "https://flagr.tools.getjust.eu/api/v1";
async function g() {
  const a = await (await fetch(`${s}/flags?enabled=true`)).json();
  return r.array().parse(a);
}
async function p(n, a) {
  const o = await (await fetch(`${s}/evaluation`, {
    method: "POST",
    body: JSON.stringify({
      flagKey: n,
      enableDebug: !1,
      entityContext: a ?? {}
    }),
    headers: {
      "Content-Type": "application/json"
    }
  })).json();
  return (o == null ? void 0 : o.variantKey) === "on";
}
async function u(n, a) {
  const e = await fetch(`${s}/evaluation`, {
    method: "POST",
    body: JSON.stringify({
      flagKey: n,
      enableDebug: !1,
      entityContext: a ?? {}
    }),
    headers: {
      "Content-Type": "application/json"
    }
  });
  return l.parse(await e.json());
}
async function f(n) {
  const e = await (await fetch(`${s}/flags/${n}`)).json();
  return r.parse(e);
}
export {
  p as evaluateFeatureFlag,
  f as getFlag,
  u as getFlagEvaluation,
  g as getFlags
};
