import { logger } from '@getjust/browser-logger';
import { useAtom, useAtomValue } from 'jotai';
import { z } from 'zod';

import { CartAtom, cartAtom } from '$src/business/atoms/cart';

const ZCartInput = z.object({
  id: z.string(),
});

export function useCartAtom() {
  const [cart, setCart] = useAtom(cartAtom);

  const onCartChange = (nextValue?: CartAtom) => {
    if (!nextValue) {
      setCart(undefined);
      return;
    }

    const parse = ZCartInput.safeParse(nextValue);
    if (parse.success) {
      setCart(parse.data);
    } else {
      logger.error('Error while parsing cart change', {
        error: parse.error,
        hook: useCartAtom.name,
      });
    }
  };

  return {
    cart,
    onCartChange,
  };
}

export function useROCartAtom() {
  return useAtomValue(cartAtom);
}
