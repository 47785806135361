import { getFlagEvaluation } from '@getjust/flagr-client';
import { useQuery } from '@tanstack/react-query';

import { useShop } from '../useShop';

const FEATURE_FLAG_QUERY_KEY = 'QUERY/FEATURE_FLAG';

export const useFeatureFlag = (key: string, params?: Record<string, any>) => {
  const { data: shop } = useShop();

  return useQuery({
    queryKey: [FEATURE_FLAG_QUERY_KEY, key],
    enabled: Boolean(shop),
    queryFn: async (ctx) => {
      const { queryKey } = ctx;
      const [, flagKey] = queryKey;

      const response = await getFlagEvaluation(flagKey as string, {
        ...(params ?? {}),
        // will always be defined, because of the enabled check above
        shopName: shop!.name,
        origin: window.location?.host,
      });

      return {
        isActive: response.variantKey === 'on',
        flagKey,
        attachement: response.variantAttachment,
      };
    },
  });
};
