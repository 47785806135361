import { AuthCustomer } from '@getjust/api-schema';

import { logger } from '@getjust/browser-logger';

import { useAtom, useAtomValue } from 'jotai';
import { useCallback } from 'react';

import { loginAtom } from '$business/atoms/login';

export function useLoginAtom() {
  const [loginInfo, setLoginAtom] = useAtom(loginAtom);

  const onLoginChange = useCallback(
    (loginQueryInfo: Partial<AuthCustomer.AuthLoginResponseBody>) => {
      const safe = AuthCustomer.ZAuthLoginResponseBody.safeParse(loginQueryInfo);

      if (safe.success) {
        setLoginAtom(safe.data);
      } else {
        logger.error('Error while parsing login change', {
          error: safe.error,
          hook: useLoginAtom.name,
        });
      }
    },
    [setLoginAtom],
  );

  return { loginInfo, onLoginChange };
}

export function useROLoginAtom() {
  const loginInfo = useAtomValue(loginAtom);

  return loginInfo;
}
