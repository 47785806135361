import { Box, HStack, Image, Stack, Text } from '@chakra-ui/react';
import { Controls, Player } from '@lottiefiles/react-lottie-player';
import { useTranslation } from 'next-i18next';

import HappyBuyers from '$animations/happyBuyers.json';
import { useShop } from '$src/hooks/queries';

export const PaidLoader = () => {
  const { t, i18n } = useTranslation();
  const { data: shop } = useShop();
  return (
    <Box
      position="relative"
      as="main"
      flex="1 1 auto"
      overflow="auto"
      p="0"
      m="0"
      height="100%"
      display="flex"
      backgroundColor="white"
      flexDirection="column"
      justifyContent="center"
      alignItems="center"
      alignContent="center"
      alignSelf="center"
      zIndex="10000"
    >
      <Stack
        w="full"
        display="flex"
        position="relative"
        alignItems="center"
        alignContent="center"
        alignSelf="center"
      >
        <Player
          src={HappyBuyers}
          autoplay
          loop
          speed={1}
          style={{ height: '13.8125rem', width: 'height: 13.8125rem' }}
        >
          <Controls visible={false} buttons={['play', 'repeat', 'frame', 'debug']} />
        </Player>
        <Text color="#1C1C1C" textAlign="center" fontWeight="medium" lineHeight="6">
          {t('loader.processing')}
        </Text>
        <Text color="#1C1C1C" textAlign="center" fontWeight="medium" lineHeight="6">
          {t('loader.dontRefresh')}
        </Text>
      </Stack>

      {shop?.name !== undefined && (
        <HStack spacing={1} pb={3.5} position="absolute" bottom="0">
          <Image alt="JUST" src="/images/smallJust.svg" />
          <Text color="#8F8F8F" textAlign="center" fontSize="xs" fontWeight="350" lineHeight="md">
            {t('loader.authorizedBy')} {shop.name}
          </Text>
        </HStack>
      )}
    </Box>
  );
};
