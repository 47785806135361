import { logger } from '@getjust/browser-logger';
import { useAtom, useAtomValue } from 'jotai';
import { useCallback } from 'react';

import { TokenAtom, tokenAtom } from '$business/atoms/session';
import { ZTokenSchema } from '$models/state';

export function useTokenAtom() {
  const [token, setToken] = useAtom(tokenAtom);

  const onTokenChange = useCallback(
    (nextToken?: TokenAtom) => {
      if (!nextToken) {
        setToken(undefined);
        return;
      }

      const safeMessage = ZTokenSchema.safeParse(nextToken);

      if (safeMessage.success) {
        setToken(safeMessage.data);
      } else {
        logger.error('Error while parsing token change', {
          error: safeMessage.error,
          hook: useTokenAtom.name,
        });
      }
    },
    [setToken],
  );

  const onTokenClear = () => setToken(undefined);

  return { token, onTokenChange, onTokenClear };
}

export function useROTokenAtom() {
  return useAtomValue(tokenAtom);
}
