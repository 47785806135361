import { useIsFetching, useIsMutating, useQueryClient } from '@tanstack/react-query';

import { usePaymentStatusAtom } from '$hooks/state';
import { ORDER_REQUEST_QUERY_KEY } from '$src/constants';
import { CREATE_ORDER_MUTATION_KEY, useCreateOrder } from '$src/hooks/mutations';

export const useIsOrderProcessing = () => {
  const { paymentStatus } = usePaymentStatusAtom();

  const isOrderRequestFetching = useIsFetching({
    queryKey: [ORDER_REQUEST_QUERY_KEY, paymentStatus?.cartId],
  });
  const client = useQueryClient();
  const data = client.getQueryData<any>([ORDER_REQUEST_QUERY_KEY, paymentStatus?.cartId]);

  const isCreateOrderMutating = useIsMutating({
    mutationKey: [CREATE_ORDER_MUTATION_KEY],
  });

  return Boolean(isOrderRequestFetching) || Boolean(data) || Boolean(isCreateOrderMutating);
};
