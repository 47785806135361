import { logger } from '@getjust/browser-logger';
import mixpanel from 'mixpanel-browser';
import posthog from 'posthog-js';

export const captureMixpanelAnalytics = (name: string, props?: { [key: string]: any }) => {
  try {
    if (!process.env.NEXT_PUBLIC_ENABLE_ANALYTICS) {
      return;
    }

    return mixpanel.track(name, props);
  } catch (error) {
    logger.error('Failed to capture mixpanel', { error });
    return;
  }
};

export const capturePosthogAnalytics = (
  name: string,
  props?: {
    user?: string;
    registered?: boolean;
    seller?: string;
    [key: string]: any;
  },
) => {
  try {
    // if (!process.env.NEXT_PUBLIC_ENABLE_ANALYTICS) {
    //   return;
    // }

    return posthog.capture(name, props);
  } catch (error) {
    logger.error('Failed to capture posthog', { error });
    return;
  }
};
