import { useToast } from '@chakra-ui/react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useTranslation } from 'next-i18next';

import type { Cart } from '@getjust/gateway';

import { CART_QUERY_KEY } from '$src/constants';
import { useBaseApiUrl } from '$src/hooks/client/useBaseApiUrl';
import { gateway } from '$src/http';
import { UpdateLineItemsInput } from '$src/pages/api/[shopId]/[cartId]/update-line-items';

const MUTATION_CART_UPDATE_LINE_ITEMS_KEY = 'MUTATION/UPDATE_LINE_ITEMS';

export const useUpdateLineItems = () => {
  const baseUrl = useBaseApiUrl();
  const toast = useToast();
  const { t } = useTranslation('common');
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: (data: UpdateLineItemsInput) => gateway.post<Cart>(`${baseUrl}/update-line-items`, data),
    mutationKey: [MUTATION_CART_UPDATE_LINE_ITEMS_KEY],
    onSuccess: ({ data }) => {
      queryClient.setQueryData<Cart>([CART_QUERY_KEY], data);
    },
    onError: () => {
      toast({
        description: t('errors.serverError'),
        status: 'error',
        position: 'top',
        isClosable: true,
        duration: 5000,
      });
    },
  });
};
