var T = {
  log: "log",
  debug: "debug",
  info: "info",
  warn: "warn",
  error: "error"
}, A = console, H = {};
Object.keys(T).forEach(function(e) {
  H[e] = A[e];
});
var ue = "Datadog Browser SDK:", E = {
  debug: H.debug.bind(A, ue),
  log: H.log.bind(A, ue),
  info: H.info.bind(A, ue),
  warn: H.warn.bind(A, ue),
  error: H.error.bind(A, ue)
};
function Rt(e, t) {
  return function() {
    for (var r = [], n = 0; n < arguments.length; n++)
      r[n] = arguments[n];
    try {
      return e.apply(void 0, r);
    } catch (o) {
      E.error(t, o);
    }
  };
}
var ge;
(function(e) {
  e.PAGEHIDE = "pagehide", e.FEATURE_FLAGS = "feature_flags", e.RESOURCE_PAGE_STATES = "resource_page_states", e.COLLECT_FLUSH_REASON = "collect_flush_reason", e.SCROLLMAP = "scrollmap", e.WEB_VITALS_ATTRIBUTION = "web_vitals_attribution", e.DISABLE_REPLAY_INLINE_CSS = "disable_replay_inline_css";
})(ge || (ge = {}));
var Ve = /* @__PURE__ */ new Set();
function vr(e) {
  e.forEach(function(t) {
    Ve.add(t);
  });
}
function Lt(e) {
  return Ve.has(e);
}
function pr() {
  return Ve;
}
function qe(e) {
  return e !== 0 && Math.random() * 100 <= e;
}
function Pe(e) {
  return gr(e) && e >= 0 && e <= 100;
}
function gr(e) {
  return typeof e == "number";
}
var he = 1e3, K = 60 * he, hr = 60 * K;
function le() {
  return (/* @__PURE__ */ new Date()).getTime();
}
function M() {
  return le();
}
function Le() {
  return performance.now();
}
function ne() {
  return { relative: Le(), timeStamp: M() };
}
function br() {
  return { relative: 0, timeStamp: xt() };
}
function mr(e, t) {
  return t - e;
}
function yr(e, t) {
  return e + t;
}
function Sr(e) {
  return e - xt();
}
var Me;
function xt() {
  return Me === void 0 && (Me = performance.timing.navigationStart), Me;
}
var P = 1024, It = 1024 * P, Er = /[^\u0000-\u007F]/;
function xe(e) {
  return Er.test(e) ? window.TextEncoder !== void 0 ? new TextEncoder().encode(e).length : new Blob([e]).size : e.length;
}
function oe(e, t) {
  return e.indexOf(t) !== -1;
}
function At(e) {
  if (Array.from)
    return Array.from(e);
  var t = [];
  if (e instanceof Set)
    e.forEach(function(n) {
      return t.push(n);
    });
  else
    for (var r = 0; r < e.length; r++)
      t.push(e[r]);
  return t;
}
function _r(e, t) {
  for (var r = 0; r < e.length; r += 1) {
    var n = e[r];
    if (t(n, r))
      return n;
  }
}
function ee(e) {
  return Object.keys(e).map(function(t) {
    return e[t];
  });
}
function Cr(e) {
  return Object.keys(e).map(function(t) {
    return [t, e[t]];
  });
}
function kt(e, t) {
  return e.slice(0, t.length) === t;
}
function Or(e, t) {
  return e.slice(-t.length) === t;
}
function O(e) {
  for (var t = [], r = 1; r < arguments.length; r++)
    t[r - 1] = arguments[r];
  return t.forEach(function(n) {
    for (var o in n)
      Object.prototype.hasOwnProperty.call(n, o) && (e[o] = n[o]);
  }), e;
}
function wr(e) {
  return O({}, e);
}
function Tr(e, t) {
  return Object.keys(e).some(function(r) {
    return e[r] === t;
  });
}
function Ut(e) {
  return Object.keys(e).length === 0;
}
function F() {
  if (typeof globalThis == "object")
    return globalThis;
  Object.defineProperty(Object.prototype, "_dd_temp_", {
    get: function() {
      return this;
    },
    configurable: !0
  });
  var e = _dd_temp_;
  return delete Object.prototype._dd_temp_, typeof e != "object" && (typeof self == "object" ? e = self : typeof window == "object" ? e = window : e = {}), e;
}
function te(e, t) {
  var r = F(), n;
  return r.Zone && typeof r.Zone.__symbol__ == "function" && (n = e[r.Zone.__symbol__(t)]), n || (n = e[t]), n;
}
var Rr = globalThis && globalThis.__spreadArray || function(e, t, r) {
  if (r || arguments.length === 2)
    for (var n = 0, o = t.length, i; n < o; n++)
      (i || !(n in t)) && (i || (i = Array.prototype.slice.call(t, 0, n)), i[n] = t[n]);
  return e.concat(i || Array.prototype.slice.call(t));
}, Ie, Nt = !1;
function Lr(e) {
  Ie = e;
}
function xr(e) {
  Nt = e;
}
function Ir(e, t, r) {
  var n = r.value;
  r.value = function() {
    for (var o = [], i = 0; i < arguments.length; i++)
      o[i] = arguments[i];
    var a = Ie ? b(n) : n;
    return a.apply(this, o);
  };
}
function b(e) {
  return function() {
    return z(e, this, arguments);
  };
}
function z(e, t, r) {
  try {
    return e.apply(t, r);
  } catch (n) {
    if (Ke(n), Ie)
      try {
        Ie(n);
      } catch (o) {
        Ke(o);
      }
  }
}
function Ke() {
  for (var e = [], t = 0; t < arguments.length; t++)
    e[t] = arguments[t];
  Nt && E.error.apply(E, Rr(["[MONITOR]"], e, !1));
}
function be(e, t) {
  return te(F(), "setTimeout")(b(e), t);
}
function Bt(e) {
  te(F(), "clearTimeout")(e);
}
function Qe(e, t) {
  return te(F(), "setInterval")(b(e), t);
}
function Pt(e) {
  te(F(), "clearInterval")(e);
}
var C = (
  /** @class */
  function() {
    function e(t) {
      this.onFirstSubscribe = t, this.observers = [];
    }
    return e.prototype.subscribe = function(t) {
      var r = this;
      return !this.observers.length && this.onFirstSubscribe && (this.onLastUnsubscribe = this.onFirstSubscribe() || void 0), this.observers.push(t), {
        unsubscribe: function() {
          r.observers = r.observers.filter(function(n) {
            return t !== n;
          }), !r.observers.length && r.onLastUnsubscribe && r.onLastUnsubscribe();
        }
      };
    }, e.prototype.notify = function(t) {
      this.observers.forEach(function(r) {
        return r(t);
      });
    }, e;
  }()
);
function Mt() {
  for (var e = [], t = 0; t < arguments.length; t++)
    e[t] = arguments[t];
  var r = new C(function() {
    var n = e.map(function(o) {
      return o.subscribe(function(i) {
        return r.notify(i);
      });
    });
    return function() {
      return n.forEach(function(o) {
        return o.unsubscribe();
      });
    };
  });
  return r;
}
function Ft(e, t, r) {
  var n = r && r.leading !== void 0 ? r.leading : !0, o = r && r.trailing !== void 0 ? r.trailing : !0, i = !1, a, s;
  return {
    throttled: function() {
      for (var c = [], u = 0; u < arguments.length; u++)
        c[u] = arguments[u];
      if (i) {
        a = c;
        return;
      }
      n ? e.apply(void 0, c) : a = c, i = !0, s = be(function() {
        o && a && e.apply(void 0, a), i = !1, a = void 0;
      }, t);
    },
    cancel: function() {
      Bt(s), i = !1, a = void 0;
    }
  };
}
function me() {
}
function W(e) {
  return e ? (
    // eslint-disable-next-line  no-bitwise
    (parseInt(e, 10) ^ Math.random() * 16 >> parseInt(e, 10) / 4).toString(16)
  ) : "".concat(1e7, "-").concat(1e3, "-").concat(4e3, "-").concat(8e3, "-").concat(1e11).replace(/[018]/g, W);
}
function Ar(e, t) {
  var r = new RegExp("(?:^|;)\\s*".concat(t, "\\s*=\\s*([^;]+)")), n = r.exec(e);
  return n ? n[1] : void 0;
}
function kr(e, t, r) {
  r === void 0 && (r = "");
  var n = e.charCodeAt(t - 1), o = n >= 55296 && n <= 56319, i = o ? t + 1 : t;
  return e.length <= i ? e : "".concat(e.slice(0, i)).concat(r);
}
var Gt = 4 * hr, Dt = 15 * K, Ee;
function Ur() {
  return Ee ?? (Ee = !!window.chrome || /HeadlessChrome/.test(window.navigator.userAgent));
}
function Ne(e, t, r, n) {
  var o = /* @__PURE__ */ new Date();
  o.setTime(o.getTime() + r);
  var i = "expires=".concat(o.toUTCString()), a = n && n.crossSite ? "none" : "strict", s = n && n.domain ? ";domain=".concat(n.domain) : "", c = n && n.secure ? ";secure" : "";
  document.cookie = "".concat(e, "=").concat(t, ";").concat(i, ";path=/;samesite=").concat(a).concat(s).concat(c);
}
function k(e) {
  return Ar(document.cookie, e);
}
function et(e, t) {
  Ne(e, "", 0, t);
}
function Nr(e) {
  if (document.cookie === void 0 || document.cookie === null)
    return !1;
  try {
    var t = "dd_cookie_test_".concat(W()), r = "test";
    Ne(t, r, K, e);
    var n = k(t) === r;
    return et(t, e), n;
  } catch (o) {
    return E.error(o), !1;
  }
}
var Fe;
function Br() {
  if (Fe === void 0) {
    for (var e = "dd_site_test_".concat(W()), t = "test", r = window.location.hostname.split("."), n = r.pop(); r.length && !k(e); )
      n = "".concat(r.pop(), ".").concat(n), Ne(e, t, he, { domain: n });
    et(e, { domain: n }), Fe = n;
  }
  return Fe;
}
var J = "_dd_s", Ht = /^([a-z]+)=([a-z0-9-]+)$/, tt = "&";
function We(e) {
  return Ut(e);
}
function jt(e) {
  e.expire = String(le() + Dt);
}
function Yt(e) {
  return Cr(e).map(function(t) {
    var r = t[0], n = t[1];
    return "".concat(r, "=").concat(n);
  }).join(tt);
}
function zt(e) {
  var t = {};
  return Pr(e) && e.split(tt).forEach(function(r) {
    var n = Ht.exec(r);
    if (n !== null) {
      var o = n[1], i = n[2];
      t[o] = i;
    }
  }), t;
}
function Pr(e) {
  return !!e && (e.indexOf(tt) !== -1 || Ht.test(e));
}
var Mr = "_dd", Fr = "_dd_r", Gr = "_dd_l", Dr = "rum", Hr = "logs";
function jr(e) {
  var t = k(J);
  if (!t) {
    var r = k(Mr), n = k(Fr), o = k(Gr), i = {};
    r && (i.id = r), o && /^[01]$/.test(o) && (i[Hr] = o), n && /^[012]$/.test(n) && (i[Dr] = n), We(i) || (jt(i), e.persistSession(i));
  }
}
function Yr(e) {
  var t = Jr(e);
  return Nr(t) ? { type: "Cookie", cookieOptions: t } : void 0;
}
function zr(e) {
  var t = {
    /**
     * Lock strategy allows mitigating issues due to concurrent access to cookie.
     * This issue concerns only chromium browsers and enabling this on firefox increases cookie write failures.
     */
    isLockEnabled: Ur(),
    persistSession: qr(e),
    retrieveSession: Kr,
    clearSession: Wr(e)
  };
  return jr(t), t;
}
function qr(e) {
  return function(t) {
    Ne(J, Yt(t), Dt, e);
  };
}
function Kr() {
  var e = k(J);
  return zt(e);
}
function Wr(e) {
  return function() {
    et(J, e);
  };
}
function Jr(e) {
  var t = {};
  return t.secure = !!e.useSecureSessionCookie || !!e.useCrossSiteSessionCookie, t.crossSite = !!e.useCrossSiteSessionCookie, e.trackSessionAcrossSubdomains && (t.domain = Br()), t;
}
var Xr = "_dd_test_";
function $r() {
  try {
    var e = W(), t = "".concat(Xr).concat(e);
    localStorage.setItem(t, e);
    var r = localStorage.getItem(t);
    return localStorage.removeItem(t), e === r ? { type: "LocalStorage" } : void 0;
  } catch {
    return;
  }
}
function Zr() {
  return {
    isLockEnabled: !1,
    persistSession: Vr,
    retrieveSession: Qr,
    clearSession: en
  };
}
function Vr(e) {
  localStorage.setItem(J, Yt(e));
}
function Qr() {
  var e = localStorage.getItem(J);
  return zt(e);
}
function en() {
  localStorage.removeItem(J);
}
var tn = 10, rn = 100, qt = [], Te;
function de(e, t, r) {
  var n;
  r === void 0 && (r = 0);
  var o = t.isLockEnabled, i = t.retrieveSession, a = t.persistSession, s = t.clearSession;
  if (Te || (Te = e), e !== Te) {
    qt.push(e);
    return;
  }
  if (o && r >= rn) {
    lt(t);
    return;
  }
  var c, u = i();
  if (o) {
    if (u.lock) {
      _e(e, t, r);
      return;
    }
    if (c = W(), u.lock = c, a(u), u = i(), u.lock !== c) {
      _e(e, t, r);
      return;
    }
  }
  var f = e.process(u);
  if (o && (u = i(), u.lock !== c)) {
    _e(e, t, r);
    return;
  }
  if (f && (We(f) ? s() : (jt(f), a(f))), o && !(f && We(f))) {
    if (u = i(), u.lock !== c) {
      _e(e, t, r);
      return;
    }
    delete u.lock, a(u), f = u;
  }
  (n = e.after) === null || n === void 0 || n.call(e, f || u), lt(t);
}
function _e(e, t, r) {
  be(function() {
    de(e, t, r + 1);
  }, tn);
}
function lt(e) {
  Te = void 0;
  var t = qt.shift();
  t && de(t, e);
}
var dt = he;
function nn(e) {
  var t = Yr(e);
  return !t && e.allowFallbackToLocalStorage && (t = $r()), t;
}
function on(e, t, r) {
  var n = new C(), o = new C(), i = e.type === "Cookie" ? zr(e.cookieOptions) : Zr(), a = i.clearSession, s = i.retrieveSession, c = Qe(h, dt), u = se();
  function f() {
    var g;
    de({
      process: function(G) {
        var Se = l(G);
        return g = d(Se), Se;
      },
      after: function(G) {
        g && !p() && L(G), u = G;
      }
    }, i);
  }
  function v() {
    de({
      process: function(g) {
        return p() ? l(g) : void 0;
      }
    }, i);
  }
  function h() {
    de({
      process: function(g) {
        return Be(g) ? void 0 : {};
      },
      after: l
    }, i);
  }
  function l(g) {
    return Be(g) || (g = {}), p() && (m(g) ? _() : u = g), g;
  }
  function d(g) {
    var G = r(g[t]), Se = G.trackingType, ft = G.isTracked;
    return g[t] = Se, ft && !g.id && (g.id = W(), g.created = String(le())), ft;
  }
  function p() {
    return u[t] !== void 0;
  }
  function m(g) {
    return u.id !== g.id || u[t] !== g[t];
  }
  function _() {
    u = {}, o.notify();
  }
  function L(g) {
    u = g, n.notify();
  }
  function se() {
    var g = s();
    return Be(g) ? g : {};
  }
  function Be(g) {
    return (g.created === void 0 || le() - Number(g.created) < Gt) && (g.expire === void 0 || le() < Number(g.expire));
  }
  return {
    expandOrRenewSession: Ft(f, dt).throttled,
    expandSession: v,
    getSession: function() {
      return u;
    },
    renewObservable: n,
    expireObservable: o,
    expire: function() {
      a(), l({});
    },
    stop: function() {
      Pt(c);
    }
  };
}
function ie(e, t, r) {
  if (typeof e != "object" || e === null)
    return JSON.stringify(e);
  var n = V(Object.prototype), o = V(Array.prototype), i = V(Object.getPrototypeOf(e)), a = V(e);
  try {
    return JSON.stringify(e, t, r);
  } catch {
    return "<error: unable to serialize object>";
  } finally {
    n(), o(), i(), a();
  }
}
function V(e) {
  var t = e, r = t.toJSON;
  return r ? (delete t.toJSON, function() {
    t.toJSON = r;
  }) : me;
}
function rt(e) {
  return an(e, un()).href;
}
function an(e, t) {
  var r = sn();
  if (r)
    try {
      return t !== void 0 ? new r(e, t) : new r(e);
    } catch (a) {
      throw new Error("Failed to construct URL: ".concat(String(a), " ").concat(ie({ url: e, base: t })));
    }
  if (t === void 0 && !/:/.test(e))
    throw new Error("Invalid URL: '".concat(e, "'"));
  var n = document, o = n.createElement("a");
  if (t !== void 0) {
    n = document.implementation.createHTMLDocument("");
    var i = n.createElement("base");
    i.href = t, n.head.appendChild(i), n.body.appendChild(o);
  }
  return o.href = e, o;
}
var vt = URL, Ce;
function sn() {
  if (Ce === void 0)
    try {
      var e = new vt("http://test/path");
      Ce = e.href === "http://test/path";
    } catch {
      Ce = !1;
    }
  return Ce ? vt : void 0;
}
function un() {
  return cn(window.location);
}
function cn(e) {
  if (e.origin && e.origin !== "null")
    return e.origin;
  var t = e.host.replace(/(:80|:443)$/, "");
  return "".concat(e.protocol, "//").concat(t);
}
var fn = "datad0g.com", ve = "datadoghq.com", ln = "ddog-gov.com";
function pe(e, t, r) {
  var n = dn(e, t);
  return {
    build: function(o, i) {
      var a = pn(e, t, r, o, i);
      return n(a);
    },
    urlPrefix: n(""),
    trackType: t
  };
}
function dn(e, t) {
  var r = "/api/v2/".concat(t), n = e.proxy;
  if (n) {
    var o = rt(n);
    return function(a) {
      return "".concat(o, "?ddforward=").concat(encodeURIComponent("".concat(r, "?").concat(a)));
    };
  }
  var i = vn(e);
  return function(a) {
    return "https://".concat(i).concat(r, "?").concat(a);
  };
}
function vn(e) {
  var t = e.site, r = t === void 0 ? ve : t, n = e.internalAnalyticsSubdomain;
  if (n && r === ve)
    return "".concat(n, ".").concat(ve);
  var o = r.split("."), i = o.pop();
  return "browser-intake-".concat(o.join("-"), ".").concat(i);
}
function pn(e, t, r, n, o) {
  var i = e.clientToken, a = e.internalAnalyticsSubdomain, s = o.retry, c = o.flushReason, u = o.encoding, f = ["sdk_version:".concat("5.1.0"), "api:".concat(n)].concat(r);
  c && Lt(ge.COLLECT_FLUSH_REASON) && f.push("flush_reason:".concat(c)), s && f.push("retry_count:".concat(s.count), "retry_after:".concat(s.lastFailureStatus));
  var v = [
    "ddsource=browser",
    "ddtags=".concat(encodeURIComponent(f.join(","))),
    "dd-api-key=".concat(i),
    "dd-evp-origin-version=".concat(encodeURIComponent("5.1.0")),
    "dd-evp-origin=browser",
    "dd-request-id=".concat(W())
  ];
  return u && v.push("dd-evp-encoding=".concat(u)), t === "rum" && v.push("batch_time=".concat(M())), a && v.reverse(), v.join("&");
}
var gn = 200;
function hn(e) {
  var t = e.env, r = e.service, n = e.version, o = e.datacenter, i = [];
  return t && i.push(Oe("env", t)), r && i.push(Oe("service", r)), n && i.push(Oe("version", n)), o && i.push(Oe("datacenter", o)), i;
}
var bn = /[^a-z0-9_:./-]/;
function Oe(e, t) {
  var r = gn - e.length - 1;
  (t.length > r || bn.test(t)) && E.warn("".concat(e, " value doesn't meet tag requirements and will be sanitized"));
  var n = t.replace(/,/g, "_");
  return "".concat(e, ":").concat(n);
}
function mn(e) {
  var t = hn(e), r = yn(e, t), n = ee(r).map(function(i) {
    return i.urlPrefix;
  }), o = Sn(e, n, t);
  return O({
    isIntakeUrl: function(i) {
      return n.some(function(a) {
        return i.indexOf(a) === 0;
      });
    },
    replica: o,
    site: e.site || ve
  }, r);
}
function yn(e, t) {
  return {
    logsEndpointBuilder: pe(e, "logs", t),
    rumEndpointBuilder: pe(e, "rum", t),
    sessionReplayEndpointBuilder: pe(e, "replay", t)
  };
}
function Sn(e, t, r) {
  if (e.replica) {
    var n = O({}, e, {
      site: ve,
      clientToken: e.replica.clientToken
    }), o = {
      logsEndpointBuilder: pe(n, "logs", r),
      rumEndpointBuilder: pe(n, "rum", r)
    };
    return t.push.apply(t, ee(o).map(function(i) {
      return i.urlPrefix;
    })), O({ applicationId: e.replica.applicationId }, o);
  }
}
function En(e) {
  var t, r, n;
  if (!e || !e.clientToken) {
    E.error("Client Token is not configured, we will not send any data.");
    return;
  }
  if (e.sessionSampleRate !== void 0 && !Pe(e.sessionSampleRate)) {
    E.error("Session Sample Rate should be a number between 0 and 100");
    return;
  }
  if (e.telemetrySampleRate !== void 0 && !Pe(e.telemetrySampleRate)) {
    E.error("Telemetry Sample Rate should be a number between 0 and 100");
    return;
  }
  if (e.telemetryConfigurationSampleRate !== void 0 && !Pe(e.telemetryConfigurationSampleRate)) {
    E.error("Telemetry Configuration Sample Rate should be a number between 0 and 100");
    return;
  }
  return Array.isArray(e.enableExperimentalFeatures) && vr(e.enableExperimentalFeatures.filter(function(o) {
    return Tr(ge, o);
  })), O({
    beforeSend: e.beforeSend && Rt(e.beforeSend, "beforeSend threw an error:"),
    sessionStoreStrategyType: nn(e),
    sessionSampleRate: (t = e.sessionSampleRate) !== null && t !== void 0 ? t : 100,
    telemetrySampleRate: (r = e.telemetrySampleRate) !== null && r !== void 0 ? r : 20,
    telemetryConfigurationSampleRate: (n = e.telemetryConfigurationSampleRate) !== null && n !== void 0 ? n : 5,
    service: e.service,
    silentMultipleInit: !!e.silentMultipleInit,
    allowUntrustedEvents: !!e.allowUntrustedEvents,
    /**
     * beacon payload max queue size implementation is 64kb
     * ensure that we leave room for logs, rum and potential other users
     */
    batchBytesLimit: 16 * P,
    eventRateLimiterThreshold: 3e3,
    maxTelemetryEventsPerPage: 15,
    /**
     * flush automatically, aim to be lower than ALB connection timeout
     * to maximize connection reuse.
     */
    flushTimeout: 30 * he,
    /**
     * Logs intake limit
     */
    batchMessagesLimit: 50,
    messageBytesLimit: 256 * P
  }, mn(e));
}
function _n(e) {
  return {
    session_sample_rate: e.sessionSampleRate,
    telemetry_sample_rate: e.telemetrySampleRate,
    telemetry_configuration_sample_rate: e.telemetryConfigurationSampleRate,
    use_before_send: !!e.beforeSend,
    use_cross_site_session_cookie: e.useCrossSiteSessionCookie,
    use_secure_session_cookie: e.useSecureSessionCookie,
    use_proxy: !!e.proxy,
    silent_multiple_init: e.silentMultipleInit,
    track_session_across_subdomains: e.trackSessionAcrossSubdomains,
    allow_fallback_to_local_storage: !!e.allowFallbackToLocalStorage,
    store_contexts_across_pages: !!e.storeContextsAcrossPages,
    allow_untrusted_events: !!e.allowUntrustedEvents
  };
}
function Kt(e, t, r) {
  var n = e[t], o = r(n), i = function() {
    if (typeof o == "function")
      return o.apply(this, arguments);
  };
  return e[t] = i, {
    stop: function() {
      e[t] === i ? e[t] = n : o = n;
    }
  };
}
function Q(e, t, r) {
  var n = r.before, o = r.after;
  return Kt(e, t, function(i) {
    return function() {
      var a = arguments, s;
      return n && z(n, this, a), typeof i == "function" && (s = i.apply(this, a)), o && z(o, this, a), s;
    };
  });
}
var ye = "?";
function N(e) {
  var t = [], r = Ge(e, "stack"), n = String(e);
  return r && kt(r, n) && (r = r.slice(n.length)), r && r.split(`
`).forEach(function(o) {
    var i = wn(o) || Rn(o) || xn(o) || kn(o);
    i && (!i.func && i.line && (i.func = ye), t.push(i));
  }), {
    message: Ge(e, "message"),
    name: Ge(e, "name"),
    stack: t
  };
}
var Wt = "((?:file|https?|blob|chrome-extension|native|eval|webpack|snippet|<anonymous>|\\w+\\.|\\/).*?)", re = "(?::(\\d+))", Cn = new RegExp("^\\s*at (.*?) ?\\(".concat(Wt).concat(re, "?").concat(re, "?\\)?\\s*$"), "i"), On = new RegExp("\\((\\S*)".concat(re).concat(re, "\\)"));
function wn(e) {
  var t = Cn.exec(e);
  if (t) {
    var r = t[2] && t[2].indexOf("native") === 0, n = t[2] && t[2].indexOf("eval") === 0, o = On.exec(t[2]);
    return n && o && (t[2] = o[1], t[3] = o[2], t[4] = o[3]), {
      args: r ? [t[2]] : [],
      column: t[4] ? +t[4] : void 0,
      func: t[1] || ye,
      line: t[3] ? +t[3] : void 0,
      url: r ? void 0 : t[2]
    };
  }
}
var Tn = new RegExp("^\\s*at ?".concat(Wt).concat(re, "?").concat(re, "??\\s*$"), "i");
function Rn(e) {
  var t = Tn.exec(e);
  if (t)
    return {
      args: [],
      column: t[3] ? +t[3] : void 0,
      func: ye,
      line: t[2] ? +t[2] : void 0,
      url: t[1]
    };
}
var Ln = /^\s*at (?:((?:\[object object\])?.+) )?\(?((?:file|ms-appx|https?|webpack|blob):.*?):(\d+)(?::(\d+))?\)?\s*$/i;
function xn(e) {
  var t = Ln.exec(e);
  if (t)
    return {
      args: [],
      column: t[4] ? +t[4] : void 0,
      func: t[1] || ye,
      line: +t[3],
      url: t[2]
    };
}
var In = /^\s*(.*?)(?:\((.*?)\))?(?:^|@)((?:file|https?|blob|chrome|webpack|resource|capacitor|\[native).*?|[^@]*bundle)(?::(\d+))?(?::(\d+))?\s*$/i, An = /(\S+) line (\d+)(?: > eval line \d+)* > eval/i;
function kn(e) {
  var t = In.exec(e);
  if (t) {
    var r = t[3] && t[3].indexOf(" > eval") > -1, n = An.exec(t[3]);
    return r && n && (t[3] = n[1], t[4] = n[2], t[5] = void 0), {
      args: t[2] ? t[2].split(",") : [],
      column: t[5] ? +t[5] : void 0,
      func: t[1] || ye,
      line: t[4] ? +t[4] : void 0,
      url: t[3]
    };
  }
}
function Ge(e, t) {
  if (!(typeof e != "object" || !e || !(t in e))) {
    var r = e[t];
    return typeof r == "string" ? r : void 0;
  }
}
function Un(e, t, r, n) {
  var o = [{ url: t, column: n, line: r }], i = Bn(e), a = i.name, s = i.message;
  return {
    name: a,
    message: s,
    stack: o
  };
}
var Nn = /^(?:[Uu]ncaught (?:exception: )?)?(?:((?:Eval|Internal|Range|Reference|Syntax|Type|URI|)Error): )?([\s\S]*)$/;
function Bn(e) {
  var t, r, n;
  return {}.toString.call(e) === "[object String]" && (t = Nn.exec(e), r = t[1], n = t[2]), { name: r, message: n };
}
var Pn = 220 * P, Mn = "$", Fn = 3;
function U(e, t) {
  var r;
  t === void 0 && (t = Pn);
  var n = V(Object.prototype), o = V(Array.prototype), i = [], a = /* @__PURE__ */ new WeakMap(), s = De(e, Mn, void 0, i, a), c = ((r = JSON.stringify(s)) === null || r === void 0 ? void 0 : r.length) || 0;
  if (c > t) {
    He(t, "discarded", e);
    return;
  }
  for (; i.length > 0 && c < t; ) {
    var u = i.shift(), f = 0;
    if (Array.isArray(u.source))
      for (var v = 0; v < u.source.length; v++) {
        var h = De(u.source[v], u.path, v, i, a);
        if (h !== void 0 ? c += JSON.stringify(h).length : c += 4, c += f, f = 1, c > t) {
          He(t, "truncated", e);
          break;
        }
        u.target[v] = h;
      }
    else
      for (var v in u.source)
        if (Object.prototype.hasOwnProperty.call(u.source, v)) {
          var h = De(u.source[v], u.path, v, i, a);
          if (h !== void 0 && (c += JSON.stringify(h).length + f + v.length + Fn, f = 1), c > t) {
            He(t, "truncated", e);
            break;
          }
          u.target[v] = h;
        }
  }
  return n(), o(), s;
}
function De(e, t, r, n, o) {
  var i = Hn(e);
  if (!i || typeof i != "object")
    return Gn(i);
  var a = Dn(i);
  if (a !== "[Object]" && a !== "[Array]" && a !== "[Error]")
    return a;
  var s = e;
  if (o.has(s))
    return "[Reference seen at ".concat(o.get(s), "]");
  var c = r !== void 0 ? "".concat(t, ".").concat(r) : t, u = Array.isArray(i) ? [] : {};
  return o.set(s, c), n.push({ source: i, target: u, path: c }), u;
}
function Gn(e) {
  return typeof e == "bigint" ? "[BigInt] ".concat(e.toString()) : typeof e == "function" ? "[Function] ".concat(e.name || "unknown") : typeof e == "symbol" ? "[Symbol] ".concat(e.description || e.toString()) : e;
}
function Dn(e) {
  try {
    if (e instanceof Event)
      return {
        isTrusted: e.isTrusted
      };
    var t = Object.prototype.toString.call(e), r = t.match(/\[object (.*)\]/);
    if (r && r[1])
      return "[".concat(r[1], "]");
  } catch {
  }
  return "[Unserializable]";
}
function Hn(e) {
  var t = e;
  if (t && typeof t.toJSON == "function")
    try {
      return t.toJSON();
    } catch {
    }
  return e;
}
function He(e, t, r) {
  E.warn("The data provided has been ".concat(t, " as it is over the limit of ").concat(e, " characters:"), r);
}
var Jt = "No stack, consider using an instance of Error";
function Xt(e) {
  var t = e.stackTrace, r = e.originalError, n = e.handlingStack, o = e.startClocks, i = e.nonErrorPrefix, a = e.source, s = e.handling, c = r instanceof Error, u = jn(t, c, i, r), f = Yn(c, t) ? X(t) : Jt, v = c ? Kn(r, a) : void 0, h = t == null ? void 0 : t.name, l = $t(r);
  return {
    startClocks: o,
    source: a,
    handling: s,
    handlingStack: n,
    originalError: r,
    type: h,
    message: u,
    stack: f,
    causes: v,
    fingerprint: l
  };
}
function jn(e, t, r, n) {
  return e != null && e.message && (e != null && e.name) ? e.message : t ? "Empty message" : "".concat(r, " ").concat(ie(U(n)));
}
function Yn(e, t) {
  return t === void 0 ? !1 : e ? !0 : t.stack.length > 0 && (t.stack.length > 1 || t.stack[0].url !== void 0);
}
function $t(e) {
  return e instanceof Error && "dd_fingerprint" in e ? String(e.dd_fingerprint) : void 0;
}
function X(e) {
  var t = Zt(e);
  return e.stack.forEach(function(r) {
    var n = r.func === "?" ? "<anonymous>" : r.func, o = r.args && r.args.length > 0 ? "(".concat(r.args.join(", "), ")") : "", i = r.line ? ":".concat(r.line) : "", a = r.line && r.column ? ":".concat(r.column) : "";
    t += `
  at `.concat(n).concat(o, " @ ").concat(r.url).concat(i).concat(a);
  }), t;
}
function zn(e) {
  var t;
  return (t = /@ (.+)/.exec(e)) === null || t === void 0 ? void 0 : t[1];
}
function Zt(e) {
  return "".concat(e.name || "Error", ": ").concat(e.message);
}
function qn() {
  var e = 2, t = new Error(), r;
  if (!t.stack)
    try {
      throw t;
    } catch {
    }
  return z(function() {
    var n = N(t);
    n.stack = n.stack.slice(e), r = X(n);
  }), r;
}
function Kn(e, t) {
  for (var r = e, n = []; (r == null ? void 0 : r.cause) instanceof Error && n.length < 10; ) {
    var o = N(r.cause);
    n.push({
      message: r.cause.message,
      source: t,
      type: o == null ? void 0 : o.name,
      stack: o && X(o)
    }), r = r.cause;
  }
  return n.length ? n : void 0;
}
var R = {
  AGENT: "agent",
  CONSOLE: "console",
  CUSTOM: "custom",
  LOGGER: "logger",
  NETWORK: "network",
  SOURCE: "source",
  REPORT: "report"
};
function Wn(e) {
  var t = function(o, i) {
    var a = Xt({
      stackTrace: o,
      originalError: i,
      startClocks: ne(),
      nonErrorPrefix: "Uncaught",
      source: R.SOURCE,
      handling: "unhandled"
    });
    e.notify(a);
  }, r = Jn(t).stop, n = Xn(t).stop;
  return {
    stop: function() {
      r(), n();
    }
  };
}
function Jn(e) {
  return Q(window, "onerror", {
    before: function(t, r, n, o, i) {
      var a;
      i instanceof Error ? a = N(i) : a = Un(t, r, n, o), e(a, i ?? t);
    }
  });
}
function Xn(e) {
  return Q(window, "onunhandledrejection", {
    before: function(t) {
      var r = t.reason || "Empty reason", n = N(r);
      e(n, r);
    }
  });
}
function $n(e) {
  var t = O({
    version: "5.1.0",
    // This API method is intentionally not monitored, since the only thing executed is the
    // user-provided 'callback'.  All SDK usages executed in the callback should be monitored, and
    // we don't want to interfere with the user uncaught exceptions.
    onReady: function(r) {
      r();
    }
  }, e);
  return Object.defineProperty(t, "_setDebug", {
    get: function() {
      return xr;
    },
    enumerable: !1
  }), t;
}
function Zn(e, t, r) {
  var n = e[t];
  e[t] = r, n && n.q && n.q.forEach(function(o) {
    return Rt(o, "onReady callback threw an error:")();
  });
}
var S;
(function(e) {
  e.BEFORE_UNLOAD = "beforeunload", e.CLICK = "click", e.DBL_CLICK = "dblclick", e.KEY_DOWN = "keydown", e.LOAD = "load", e.POP_STATE = "popstate", e.SCROLL = "scroll", e.TOUCH_START = "touchstart", e.TOUCH_END = "touchend", e.TOUCH_MOVE = "touchmove", e.VISIBILITY_CHANGE = "visibilitychange", e.PAGE_SHOW = "pageshow", e.FREEZE = "freeze", e.RESUME = "resume", e.DOM_CONTENT_LOADED = "DOMContentLoaded", e.POINTER_DOWN = "pointerdown", e.POINTER_UP = "pointerup", e.POINTER_CANCEL = "pointercancel", e.HASH_CHANGE = "hashchange", e.PAGE_HIDE = "pagehide", e.MOUSE_DOWN = "mousedown", e.MOUSE_UP = "mouseup", e.MOUSE_MOVE = "mousemove", e.FOCUS = "focus", e.BLUR = "blur", e.CONTEXT_MENU = "contextmenu", e.RESIZE = "resize", e.CHANGE = "change", e.INPUT = "input", e.PLAY = "play", e.PAUSE = "pause", e.SECURITY_POLICY_VIOLATION = "securitypolicyviolation", e.SELECTION_CHANGE = "selectionchange", e.STORAGE = "storage";
})(S || (S = {}));
function ae(e, t, r, n, o) {
  return nt(e, t, [r], n, o);
}
function nt(e, t, r, n, o) {
  var i = o === void 0 ? {} : o, a = i.once, s = i.capture, c = i.passive, u = b(function(l) {
    !l.isTrusted && !l.__ddIsTrusted && !e.allowUntrustedEvents || (a && h(), n(l));
  }), f = c ? { capture: s, passive: c } : s, v = te(t, "addEventListener");
  r.forEach(function(l) {
    return v.call(t, l, u, f);
  });
  function h() {
    var l = te(t, "removeEventListener");
    r.forEach(function(d) {
      return l.call(t, d, u, f);
    });
  }
  return {
    stop: h
  };
}
var B = {
  intervention: "intervention",
  deprecation: "deprecation",
  cspViolation: "csp_violation"
};
function Vn(e, t) {
  var r = [];
  oe(t, B.cspViolation) && r.push(eo(e));
  var n = t.filter(function(o) {
    return o !== B.cspViolation;
  });
  return n.length && r.push(Qn(n)), Mt.apply(void 0, r);
}
function Qn(e) {
  var t = new C(function() {
    if (window.ReportingObserver) {
      var r = b(function(o, i) {
        return o.forEach(function(a) {
          t.notify(to(a));
        });
      }), n = new window.ReportingObserver(r, {
        types: e,
        buffered: !0
      });
      return n.observe(), function() {
        n.disconnect();
      };
    }
  });
  return t;
}
function eo(e) {
  var t = new C(function() {
    var r = ae(e, document, S.SECURITY_POLICY_VIOLATION, function(n) {
      t.notify(ro(n));
    }).stop;
    return r;
  });
  return t;
}
function to(e) {
  var t = e.type, r = e.body;
  return {
    type: t,
    subtype: r.id,
    message: "".concat(t, ": ").concat(r.message),
    stack: Vt(r.id, r.message, r.sourceFile, r.lineNumber, r.columnNumber)
  };
}
function ro(e) {
  var t = B.cspViolation, r = "'".concat(e.blockedURI, "' blocked by '").concat(e.effectiveDirective, "' directive");
  return {
    type: B.cspViolation,
    subtype: e.effectiveDirective,
    message: "".concat(t, ": ").concat(r),
    stack: Vt(e.effectiveDirective, e.originalPolicy ? "".concat(r, ' of the policy "').concat(kr(e.originalPolicy, 100, "..."), '"') : "no policy", e.sourceFile, e.lineNumber, e.columnNumber)
  };
}
function Vt(e, t, r, n, o) {
  return r ? X({
    name: e,
    message: t,
    stack: [
      {
        func: "?",
        url: r,
        line: n ?? void 0,
        column: o ?? void 0
      }
    ]
  }) : void 0;
}
function Qt(e, t) {
  var r = window.__ddBrowserSdkExtensionCallback;
  r && r({ type: e, payload: t });
}
function ot(e) {
  return e === null ? "null" : Array.isArray(e) ? "array" : typeof e;
}
function Ae(e, t, r) {
  if (r === void 0 && (r = no()), t === void 0)
    return e;
  if (typeof t != "object" || t === null)
    return t;
  if (t instanceof Date)
    return new Date(t.getTime());
  if (t instanceof RegExp) {
    var n = t.flags || // old browsers compatibility
    [
      t.global ? "g" : "",
      t.ignoreCase ? "i" : "",
      t.multiline ? "m" : "",
      t.sticky ? "y" : "",
      t.unicode ? "u" : ""
    ].join("");
    return new RegExp(t.source, n);
  }
  if (!r.hasAlreadyBeenSeen(t)) {
    if (Array.isArray(t)) {
      for (var o = Array.isArray(e) ? e : [], i = 0; i < t.length; ++i)
        o[i] = Ae(o[i], t[i], r);
      return o;
    }
    var a = ot(e) === "object" ? e : {};
    for (var s in t)
      Object.prototype.hasOwnProperty.call(t, s) && (a[s] = Ae(a[s], t[s], r));
    return a;
  }
}
function Je(e) {
  return Ae(void 0, e);
}
function q() {
  for (var e = [], t = 0; t < arguments.length; t++)
    e[t] = arguments[t];
  for (var r, n = 0, o = e; n < o.length; n++) {
    var i = o[n];
    i != null && (r = Ae(r, i));
  }
  return r;
}
function no() {
  if (typeof WeakSet < "u") {
    var e = /* @__PURE__ */ new WeakSet();
    return {
      hasAlreadyBeenSeen: function(r) {
        var n = e.has(r);
        return n || e.add(r), n;
      }
    };
  }
  var t = [];
  return {
    hasAlreadyBeenSeen: function(r) {
      var n = t.indexOf(r) >= 0;
      return n || t.push(r), n;
    }
  };
}
var it = {
  log: "log",
  configuration: "configuration"
}, oo = [
  "https://www.datadoghq-browser-agent.com",
  "https://www.datad0g-browser-agent.com",
  "https://d3uc069fcn7uxw.cloudfront.net",
  "https://d20xtzwzcl0ceb.cloudfront.net",
  "http://localhost",
  "<anonymous>"
], io = [ln], I = { maxEventsPerPage: 0, sentEventCount: 0, telemetryEnabled: !1, telemetryConfigurationEnabled: !1 }, Xe;
function ao(e, t) {
  var r, n = new C();
  I.telemetryEnabled = !oe(io, t.site) && qe(t.telemetrySampleRate), I.telemetryConfigurationEnabled = I.telemetryEnabled && qe(t.telemetryConfigurationSampleRate);
  var o = so();
  Xe = function(a) {
    if (I.telemetryEnabled) {
      var s = i(e, a, o);
      n.notify(s), Qt("telemetry", s);
    }
  }, Lr(tr), O(I, {
    maxEventsPerPage: t.maxTelemetryEventsPerPage,
    sentEventCount: 0
  });
  function i(a, s, c) {
    return q({
      type: "telemetry",
      date: M(),
      service: a,
      version: "5.1.0",
      source: "browser",
      _dd: {
        format_version: 2
      },
      telemetry: q(s, {
        runtime_env: c
      }),
      experimental_features: At(pr())
    }, r !== void 0 ? r() : {});
  }
  return {
    setContextProvider: function(a) {
      r = a;
    },
    observable: n,
    enabled: I.telemetryEnabled
  };
}
function so() {
  return {
    is_local_file: window.location.protocol === "file:",
    is_worker: "WorkerGlobalScope" in self
  };
}
function uo(e) {
  return e.site === fn;
}
function er(e, t) {
  Ke(T.debug, e, t), at(O({
    type: it.log,
    message: e,
    status: "debug"
  }, t));
}
function tr(e, t) {
  at(O({
    type: it.log,
    status: "error"
  }, fo(e), t));
}
function co(e) {
  I.telemetryConfigurationEnabled && at({
    type: it.configuration,
    configuration: e
  });
}
function at(e) {
  Xe && I.sentEventCount < I.maxEventsPerPage && (I.sentEventCount += 1, Xe(e));
}
function fo(e) {
  if (e instanceof Error) {
    var t = N(e);
    return {
      error: {
        kind: t.name,
        stack: X(lo(t))
      },
      message: t.message
    };
  }
  return {
    error: {
      stack: Jt
    },
    message: "".concat("Uncaught", " ").concat(ie(e))
  };
}
function lo(e) {
  return e.stack = e.stack.filter(function(t) {
    return !t.url || oo.some(function(r) {
      return kt(t.url, r);
    });
  }), e;
}
var we = 1 / 0, vo = K, po = (
  /** @class */
  function() {
    function e(t, r) {
      var n = this;
      this.expireDelay = t, this.maxEntries = r, this.entries = [], this.clearOldValuesInterval = Qe(function() {
        return n.clearOldValues();
      }, vo);
    }
    return e.prototype.add = function(t, r) {
      var n = this, o = {
        value: t,
        startTime: r,
        endTime: we,
        remove: function() {
          var i = n.entries.indexOf(o);
          i >= 0 && n.entries.splice(i, 1);
        },
        close: function(i) {
          o.endTime = i;
        }
      };
      return this.maxEntries && this.entries.length >= this.maxEntries && this.entries.pop(), this.entries.unshift(o), o;
    }, e.prototype.find = function(t) {
      t === void 0 && (t = we);
      for (var r = 0, n = this.entries; r < n.length; r++) {
        var o = n[r];
        if (o.startTime <= t) {
          if (t <= o.endTime)
            return o.value;
          break;
        }
      }
    }, e.prototype.closeActive = function(t) {
      var r = this.entries[0];
      r && r.endTime === we && r.close(t);
    }, e.prototype.findAll = function(t, r) {
      t === void 0 && (t = we), r === void 0 && (r = 0);
      var n = yr(t, r);
      return this.entries.filter(function(o) {
        return o.startTime <= n && t <= o.endTime;
      }).map(function(o) {
        return o.value;
      });
    }, e.prototype.reset = function() {
      this.entries = [];
    }, e.prototype.stop = function() {
      Pt(this.clearOldValuesInterval);
    }, e.prototype.clearOldValues = function() {
      for (var t = Le() - this.expireDelay; this.entries.length > 0 && this.entries[this.entries.length - 1].endTime < t; )
        this.entries.pop();
    }, e;
  }()
), go = K, ho = Gt;
function bo(e, t, r) {
  var n = on(e.sessionStoreStrategyType, t, r), o = new po(ho);
  n.renewObservable.subscribe(function() {
    o.add(i(), Le());
  }), n.expireObservable.subscribe(function() {
    o.closeActive(Le());
  }), n.expandOrRenewSession(), o.add(i(), br().relative), mo(e, function() {
    return n.expandOrRenewSession();
  }), yo(e, function() {
    return n.expandSession();
  });
  function i() {
    return {
      id: n.getSession().id,
      trackingType: n.getSession()[t]
    };
  }
  return {
    findActiveSession: function(a) {
      return o.find(a);
    },
    renewObservable: n.renewObservable,
    expireObservable: n.expireObservable,
    expire: n.expire
  };
}
function mo(e, t) {
  nt(e, window, [S.CLICK, S.TOUCH_START, S.KEY_DOWN, S.SCROLL], t, { capture: !0, passive: !0 }).stop;
}
function yo(e, t) {
  var r = function() {
    document.visibilityState === "visible" && t();
  };
  ae(e, document, S.VISIBILITY_CHANGE, r).stop, Qe(r, go);
}
function rr(e) {
  return e >= 500;
}
function So(e) {
  try {
    return e.clone();
  } catch {
    return;
  }
}
var Eo = 80 * P, _o = 32, nr = 3 * It, Co = K, or = he;
function ir(e, t, r, n, o) {
  t.transportStatus === 0 && t.queuedPayloads.size() === 0 && t.bandwidthMonitor.canHandle(e) ? sr(e, t, r, {
    onSuccess: function() {
      return ur(0, t, r, n, o);
    },
    onFailure: function() {
      t.queuedPayloads.enqueue(e), ar(t, r, n, o);
    }
  }) : t.queuedPayloads.enqueue(e);
}
function ar(e, t, r, n) {
  e.transportStatus === 2 && be(function() {
    var o = e.queuedPayloads.first();
    sr(o, e, t, {
      onSuccess: function() {
        e.queuedPayloads.dequeue(), e.currentBackoffTime = or, ur(1, e, t, r, n);
      },
      onFailure: function() {
        e.currentBackoffTime = Math.min(Co, e.currentBackoffTime * 2), ar(e, t, r, n);
      }
    });
  }, e.currentBackoffTime);
}
function sr(e, t, r, n) {
  var o = n.onSuccess, i = n.onFailure;
  t.bandwidthMonitor.add(e), r(e, function(a) {
    t.bandwidthMonitor.remove(e), Oo(a) ? (t.transportStatus = t.bandwidthMonitor.ongoingRequestCount > 0 ? 1 : 2, e.retry = {
      count: e.retry ? e.retry.count + 1 : 1,
      lastFailureStatus: a.status
    }, i()) : (t.transportStatus = 0, o());
  });
}
function ur(e, t, r, n, o) {
  e === 0 && t.queuedPayloads.isFull() && !t.queueFullReported && (o({
    message: "Reached max ".concat(n, " events size queued for upload: ").concat(nr / It, "MiB"),
    source: R.AGENT,
    startClocks: ne()
  }), t.queueFullReported = !0);
  var i = t.queuedPayloads;
  for (t.queuedPayloads = cr(); i.size() > 0; )
    ir(i.dequeue(), t, r, n, o);
}
function Oo(e) {
  return e.type !== "opaque" && (e.status === 0 && !navigator.onLine || e.status === 408 || e.status === 429 || rr(e.status));
}
function wo() {
  return {
    transportStatus: 0,
    currentBackoffTime: or,
    bandwidthMonitor: To(),
    queuedPayloads: cr(),
    queueFullReported: !1
  };
}
function cr() {
  var e = [];
  return {
    bytesCount: 0,
    enqueue: function(t) {
      this.isFull() || (e.push(t), this.bytesCount += t.bytesCount);
    },
    first: function() {
      return e[0];
    },
    dequeue: function() {
      var t = e.shift();
      return t && (this.bytesCount -= t.bytesCount), t;
    },
    size: function() {
      return e.length;
    },
    isFull: function() {
      return this.bytesCount >= nr;
    }
  };
}
function To() {
  return {
    ongoingRequestCount: 0,
    ongoingByteCount: 0,
    canHandle: function(e) {
      return this.ongoingRequestCount === 0 || this.ongoingByteCount + e.bytesCount <= Eo && this.ongoingRequestCount < _o;
    },
    add: function(e) {
      this.ongoingRequestCount += 1, this.ongoingByteCount += e.bytesCount;
    },
    remove: function(e) {
      this.ongoingRequestCount -= 1, this.ongoingByteCount -= e.bytesCount;
    }
  };
}
function Ro(e, t, r, n) {
  var o = wo(), i = function(a, s) {
    return Io(e, t, r, a, s);
  };
  return {
    send: function(a) {
      ir(a, o, i, t.trackType, n);
    },
    /**
     * Since fetch keepalive behaves like regular fetch on Firefox,
     * keep using sendBeaconStrategy on exit
     */
    sendOnExit: function(a) {
      Lo(e, t, r, a);
    }
  };
}
function Lo(e, t, r, n) {
  var o = !!navigator.sendBeacon && n.bytesCount < r;
  if (o)
    try {
      var i = t.build("beacon", n), a = navigator.sendBeacon(i, n.data);
      if (a)
        return;
    } catch (c) {
      xo(c);
    }
  var s = t.build("xhr", n);
  $e(e, s, n.data);
}
var pt = !1;
function xo(e) {
  pt || (pt = !0, tr(e));
}
function Io(e, t, r, n, o) {
  var i = Ao() && n.bytesCount < r;
  if (i) {
    var a = t.build("fetch", n);
    fetch(a, { method: "POST", body: n.data, keepalive: !0, mode: "cors" }).then(b(function(c) {
      return o == null ? void 0 : o({ status: c.status, type: c.type });
    }), b(function() {
      var c = t.build("xhr", n);
      $e(e, c, n.data, o);
    }));
  } else {
    var s = t.build("xhr", n);
    $e(e, s, n.data, o);
  }
}
function Ao() {
  try {
    return window.Request && "keepalive" in new Request("http://a");
  } catch {
    return !1;
  }
}
function $e(e, t, r, n) {
  var o = new XMLHttpRequest();
  o.open("POST", t, !0), ae(e, o, "loadend", function() {
    n == null || n({ status: o.status });
  }, {
    // prevent multiple onResponse callbacks
    // if the xhr instance is reused by a third party
    once: !0
  }), o.send(r);
}
function st() {
  var e = ko();
  if (e)
    return {
      getAllowedWebViewHosts: function() {
        return JSON.parse(e.getAllowedWebViewHosts());
      },
      send: function(t, r) {
        e.send(JSON.stringify({ eventType: t, event: r }));
      }
    };
}
function ke(e) {
  var t;
  e === void 0 && (e = (t = F().location) === null || t === void 0 ? void 0 : t.hostname);
  var r = st();
  return !!r && r.getAllowedWebViewHosts().some(function(n) {
    return e === n || Or(e, ".".concat(n));
  });
}
function ko() {
  return F().DatadogEventBridge;
}
var fe = {
  HIDDEN: "visibility_hidden",
  UNLOADING: "before_unload",
  PAGEHIDE: "page_hide",
  FROZEN: "page_frozen"
};
function Uo(e) {
  var t = new C(function() {
    var r = Lt(ge.PAGEHIDE), n = nt(e, window, [S.VISIBILITY_CHANGE, S.FREEZE, S.PAGE_HIDE], function(i) {
      i.type === S.PAGE_HIDE && r ? t.notify({ reason: fe.PAGEHIDE }) : i.type === S.VISIBILITY_CHANGE && document.visibilityState === "hidden" ? t.notify({ reason: fe.HIDDEN }) : i.type === S.FREEZE && t.notify({ reason: fe.FROZEN });
    }, { capture: !0 }).stop, o = me;
    return r || (o = ae(e, window, S.BEFORE_UNLOAD, function() {
      t.notify({ reason: fe.UNLOADING });
    }).stop), function() {
      n(), o();
    };
  });
  return t;
}
function No(e) {
  return oe(ee(fe), e);
}
var Bo = (
  /** @class */
  function() {
    function e(t, r, n) {
      var o = this;
      this.request = t, this.flushController = r, this.messageBytesLimit = n, this.pushOnlyBuffer = [], this.upsertBuffer = {}, this.flushSubscription = this.flushController.flushObservable.subscribe(function(i) {
        return o.flush(i);
      });
    }
    return e.prototype.add = function(t) {
      this.addOrUpdate(t);
    }, e.prototype.upsert = function(t, r) {
      this.addOrUpdate(t, r);
    }, e.prototype.stop = function() {
      this.flushSubscription.unsubscribe();
    }, e.prototype.flush = function(t) {
      var r = this.pushOnlyBuffer.concat(ee(this.upsertBuffer));
      this.pushOnlyBuffer = [], this.upsertBuffer = {};
      var n = { data: r.join(`
`), bytesCount: t.bytesCount, flushReason: t.reason };
      No(t.reason) ? this.request.sendOnExit(n) : this.request.send(n);
    }, e.prototype.addOrUpdate = function(t, r) {
      var n = this.process(t), o = n.processedMessage, i = n.messageBytesCount;
      if (i >= this.messageBytesLimit) {
        E.warn("Discarded a message whose size was bigger than the maximum allowed size ".concat(this.messageBytesLimit, "KB."));
        return;
      }
      this.hasMessageFor(r) && this.remove(r), this.push(o, i, r);
    }, e.prototype.process = function(t) {
      var r = ie(t), n = xe(r);
      return { processedMessage: r, messageBytesCount: n };
    }, e.prototype.push = function(t, r, n) {
      var o = this.flushController.messagesCount > 0 ? 1 : 0;
      this.flushController.notifyBeforeAddMessage(r + o), n !== void 0 ? this.upsertBuffer[n] = t : this.pushOnlyBuffer.push(t), this.flushController.notifyAfterAddMessage();
    }, e.prototype.remove = function(t) {
      var r = this.upsertBuffer[t];
      delete this.upsertBuffer[t];
      var n = xe(r), o = this.flushController.messagesCount > 1 ? 1 : 0;
      this.flushController.notifyAfterRemoveMessage(n + o);
    }, e.prototype.hasMessageFor = function(t) {
      return t !== void 0 && this.upsertBuffer[t] !== void 0;
    }, e;
  }()
);
function Po(e) {
  var t = e.messagesLimit, r = e.bytesLimit, n = e.durationLimit, o = e.pageExitObservable, i = e.sessionExpireObservable, a = o.subscribe(function(p) {
    return v(p.reason);
  }), s = i.subscribe(function() {
    return v("session_expire");
  }), c = new C(function() {
    return function() {
      a.unsubscribe(), s.unsubscribe();
    };
  }), u = 0, f = 0;
  function v(p) {
    if (f !== 0) {
      var m = f, _ = u;
      f = 0, u = 0, d(), c.notify({
        reason: p,
        messagesCount: m,
        bytesCount: _
      });
    }
  }
  var h;
  function l() {
    h === void 0 && (h = be(function() {
      v("duration_limit");
    }, n));
  }
  function d() {
    Bt(h), h = void 0;
  }
  return {
    flushObservable: c,
    get messagesCount() {
      return f;
    },
    /**
     * Notifies that a message will be added to a pool of pending messages waiting to be flushed.
     *
     * This function needs to be called synchronously, right before adding the message, so no flush
     * event can happen after `notifyBeforeAddMessage` and before adding the message.
     */
    notifyBeforeAddMessage: function(p) {
      u + p >= r && v("bytes_limit"), f += 1, u += p, l();
    },
    /**
     * Notifies that a message *was* added to a pool of pending messages waiting to be flushed.
     *
     * This function can be called asynchronously after the message was added, but in this case it
     * should not be called if a flush event occurred in between.
     */
    notifyAfterAddMessage: function() {
      f >= t ? v("messages_limit") : u >= r && v("bytes_limit");
    },
    /**
     * Notifies that a message was removed from a pool of pending messages waiting to be flushed.
     *
     * This function needs to be called synchronously, right after removing the message, so no flush
     * event can happen after removing the message and before `notifyAfterRemoveMessage`.
     */
    notifyAfterRemoveMessage: function(p) {
      u -= p, f -= 1, f === 0 && d();
    }
  };
}
function fr(e, t, r, n, o, i) {
  var a = c(e, t.endpoint), s = r && c(e, r.endpoint);
  function c(u, f) {
    return new Bo(Ro(u, f, u.batchBytesLimit, n), Po({
      messagesLimit: u.batchMessagesLimit,
      bytesLimit: u.batchBytesLimit,
      durationLimit: u.flushTimeout,
      pageExitObservable: o,
      sessionExpireObservable: i
    }), u.messageBytesLimit);
  }
  return {
    flushObservable: a.flushController.flushObservable,
    add: function(u, f) {
      f === void 0 && (f = !0), a.add(u), s && f && s.add(r.transformMessage ? r.transformMessage(u) : u);
    },
    upsert: function(u, f) {
      a.upsert(u, f), s && s.upsert(r.transformMessage ? r.transformMessage(u) : u, f);
    },
    stop: function() {
      a.stop(), s == null || s.stop();
    }
  };
}
function Mo(e) {
  var t = /* @__PURE__ */ new Set();
  return e.forEach(function(r) {
    return t.add(r);
  }), At(t);
}
var Fo = (
  /** @class */
  function() {
    function e() {
      this.callbacks = {};
    }
    return e.prototype.notify = function(t, r) {
      var n = this.callbacks[t];
      n && n.forEach(function(o) {
        return o(r);
      });
    }, e.prototype.subscribe = function(t, r) {
      var n = this;
      return this.callbacks[t] || (this.callbacks[t] = []), this.callbacks[t].push(r), {
        unsubscribe: function() {
          n.callbacks[t] = n.callbacks[t].filter(function(o) {
            return r !== o;
          });
        }
      };
    }, e;
  }()
);
function Go(e, t, r) {
  var n = 0, o = !1;
  return {
    isLimitReached: function() {
      if (n === 0 && be(function() {
        n = 0;
      }, K), n += 1, n <= t || o)
        return o = !1, !1;
      if (n === t + 1) {
        o = !0;
        try {
          r({
            message: "Reached max number of ".concat(e, "s by minute: ").concat(t),
            source: R.AGENT,
            startClocks: ne()
          });
        } finally {
          o = !1;
        }
      }
      return !0;
    }
  };
}
var je, ut = /* @__PURE__ */ new WeakMap();
function Do(e) {
  return je || (je = Ho(e)), je;
}
function Ho(e) {
  var t = new C(function() {
    var r = Q(XMLHttpRequest.prototype, "open", {
      before: jo
    }).stop, n = Q(XMLHttpRequest.prototype, "send", {
      before: function() {
        Yo.call(this, e, t);
      }
    }).stop, o = Q(XMLHttpRequest.prototype, "abort", {
      before: zo
    }).stop;
    return function() {
      r(), n(), o();
    };
  });
  return t;
}
function jo(e, t) {
  ut.set(this, {
    state: "open",
    method: e.toUpperCase(),
    url: rt(String(t))
  });
}
function Yo(e, t) {
  var r = this, n = ut.get(this);
  if (n) {
    var o = n;
    o.state = "start", o.startClocks = ne(), o.isAborted = !1, o.xhr = this;
    var i = !1, a = Q(this, "onreadystatechange", {
      before: function() {
        this.readyState === XMLHttpRequest.DONE && s();
      }
    }).stop, s = function() {
      if (c(), a(), !i) {
        i = !0;
        var u = n;
        u.state = "complete", u.duration = mr(o.startClocks.timeStamp, M()), u.status = r.status, t.notify(wr(u));
      }
    }, c = ae(e, this, "loadend", s).stop;
    t.notify(o);
  }
}
function zo() {
  var e = ut.get(this);
  e && (e.isAborted = !0);
}
var Ye;
function qo() {
  return Ye || (Ye = Ko()), Ye;
}
function Ko() {
  var e = new C(function() {
    if (window.fetch) {
      var t = Kt(window, "fetch", function(r) {
        return function(n, o) {
          var i, a = z(Wo, null, [e, n, o]);
          return a ? (i = r.call(this, a.input, a.init), z(Jo, null, [e, i, a])) : i = r.call(this, n, o), i;
        };
      }).stop;
      return t;
    }
  });
  return e;
}
function Wo(e, t, r) {
  var n = r && r.method || t instanceof Request && t.method, o = n ? n.toUpperCase() : "GET", i = t instanceof Request ? t.url : rt(String(t)), a = ne(), s = {
    state: "start",
    init: r,
    input: t,
    method: o,
    startClocks: a,
    url: i
  };
  return e.notify(s), s;
}
function Jo(e, t, r) {
  var n = function(o) {
    var i = r;
    i.state = "resolve", "stack" in o || o instanceof Error ? (i.status = 0, i.isAborted = o instanceof DOMException && o.code === DOMException.ABORT_ERR, i.error = o) : "status" in o && (i.response = o, i.responseType = o.type, i.status = o.status, i.isAborted = !1), e.notify(i);
  };
  t.then(b(n), b(n));
}
var ze = {};
function Xo(e) {
  var t = e.map(function(r) {
    return ze[r] || (ze[r] = $o(r)), ze[r];
  });
  return Mt.apply(void 0, t);
}
function $o(e) {
  var t = new C(function() {
    var r = A[e];
    return A[e] = function() {
      for (var n = [], o = 0; o < arguments.length; o++)
        n[o] = arguments[o];
      r.apply(console, n);
      var i = qn();
      z(function() {
        t.notify(Zo(n, e, i));
      });
    }, function() {
      A[e] = r;
    };
  });
  return t;
}
function Zo(e, t, r) {
  var n = e.map(function(s) {
    return Vo(s);
  }).join(" "), o, i;
  if (t === T.error) {
    var a = _r(e, function(s) {
      return s instanceof Error;
    });
    o = a ? X(N(a)) : void 0, i = $t(a);
  }
  return {
    api: t,
    message: n,
    stack: o,
    handlingStack: r,
    fingerprint: i
  };
}
function Vo(e) {
  return typeof e == "string" ? U(e) : e instanceof Error ? Zt(N(e)) : ie(U(e), void 0, 2);
}
var Qo = 500, ei = (
  /** @class */
  function() {
    function e() {
      this.buffer = [];
    }
    return e.prototype.add = function(t) {
      var r = this.buffer.push(t);
      r > Qo && this.buffer.splice(0, 1);
    }, e.prototype.drain = function() {
      this.buffer.forEach(function(t) {
        return t();
      }), this.buffer.length = 0;
    }, e;
  }()
), $, gt = 3 * P, ti = ($ = {}, $[
  0
  /* CustomerDataType.FeatureFlag */
] = "feature flag evaluation", $[
  1
  /* CustomerDataType.User */
] = "user", $[
  2
  /* CustomerDataType.GlobalContext */
] = "global context", $[
  3
  /* CustomerDataType.LoggerContext */
] = "logger context", $);
function ri(e, t) {
  return e > gt ? (E.warn("The ".concat(ti[t], " data exceeds the recommended ").concat(gt / P, "KiB threshold. More details: https://docs.datadoghq.com/real_user_monitoring/browser/troubleshooting/#customer-data-exceeds-the-recommended-3kib-warning")), !0) : !1;
}
var ni = 200;
function Ue(e, t) {
  t === void 0 && (t = xe);
  var r = {}, n, o = !1, i = new C(), a = Ft(function(c) {
    n = t(ie(c)), o || (o = ri(n, e));
  }, ni).throttled, s = {
    getBytesCount: function() {
      return n;
    },
    getContext: function() {
      return Je(r);
    },
    setContext: function(c) {
      ot(c) === "object" ? (r = U(c), a(r)) : s.clearContext(), i.notify();
    },
    setContextProperty: function(c, u) {
      r[c] = U(u), a(r), i.notify();
    },
    removeContextProperty: function(c) {
      delete r[c], a(r), i.notify();
    },
    clearContext: function() {
      r = {}, n = 0, i.notify();
    },
    changeObservable: i
  };
  return s;
}
var oi = "_dd_c", ii = [];
function ht(e, t, r, n) {
  n === void 0 && (n = xe);
  var o = ai(t, r), i = Ue(r, n);
  return a(), ii.push(ae(e, window, S.STORAGE, function(c) {
    var u = c.key;
    o === u && a();
  })), i.changeObservable.subscribe(s), i;
  function a() {
    var c = localStorage.getItem(o), u = c !== null ? JSON.parse(c) : {};
    i.setContext(u);
  }
  function s() {
    localStorage.setItem(o, JSON.stringify(i.getContext()));
  }
}
function ai(e, t) {
  return "".concat(oi, "_").concat(e, "_").concat(t);
}
function si(e, t, r) {
  var n = e.getReader(), o = [], i = 0;
  a();
  function a() {
    n.read().then(b(function(c) {
      if (c.done) {
        s();
        return;
      }
      r.collectStreamBody && o.push(c.value), i += c.value.length, i > r.bytesLimit ? s() : a();
    }), b(function(c) {
      return t(c);
    }));
  }
  function s() {
    n.cancel().catch(
      // we don't care if cancel fails, but we still need to catch the error to avoid reporting it
      // as an unhandled rejection
      me
    );
    var c, u;
    if (r.collectStreamBody) {
      var f;
      if (o.length === 1)
        f = o[0];
      else {
        f = new Uint8Array(i);
        var v = 0;
        o.forEach(function(h) {
          f.set(h, v), v += h.length;
        });
      }
      c = f.slice(0, r.bytesLimit), u = f.length > r.bytesLimit;
    }
    t(void 0, c, u);
  }
}
var ui = "datadog-synthetics-public-id", ci = "datadog-synthetics-result-id", fi = "datadog-synthetics-injects-rum";
function lr() {
  return !!(window._DATADOG_SYNTHETICS_INJECTS_RUM || k(fi));
}
function li() {
  var e = window._DATADOG_SYNTHETICS_PUBLIC_ID || k(ui);
  return typeof e == "string" ? e : void 0;
}
function di() {
  var e = window._DATADOG_SYNTHETICS_RESULT_ID || k(ci);
  return typeof e == "string" ? e : void 0;
}
function bt(e) {
  var t = O({}, e), r = ["id", "name", "email"];
  return r.forEach(function(n) {
    n in t && (t[n] = String(t[n]));
  }), t;
}
function vi(e) {
  var t = ot(e) === "object";
  return t || E.error("Unsupported user:", e), t;
}
var pi = 32 * P;
function gi(e) {
  var t = En(e), r = mt(e.forwardConsoleLogs, ee(T), "Forward Console Logs"), n = mt(e.forwardReports, ee(B), "Forward Reports");
  if (!(!t || !r || !n))
    return e.forwardErrorsToLogs && !oe(r, T.error) && r.push(T.error), O({
      forwardErrorsToLogs: e.forwardErrorsToLogs !== !1,
      forwardConsoleLogs: r,
      forwardReports: n,
      requestErrorResponseLengthLimit: pi
    }, t);
}
function mt(e, t, r) {
  if (e === void 0)
    return [];
  if (!(e === "all" || Array.isArray(e) && e.every(function(n) {
    return oe(t, n);
  }))) {
    E.error("".concat(r, ' should be "all" or an array with allowed values "').concat(t.join('", "'), '"'));
    return;
  }
  return e === "all" ? t : Mo(e);
}
function hi(e) {
  var t = _n(e);
  return O({
    forward_errors_to_logs: e.forwardErrorsToLogs,
    forward_console_logs: e.forwardConsoleLogs,
    forward_reports: e.forwardReports
  }, t);
}
var bi = globalThis && globalThis.__decorate || function(e, t, r, n) {
  var o = arguments.length, i = o < 3 ? t : n === null ? n = Object.getOwnPropertyDescriptor(t, r) : n, a;
  if (typeof Reflect == "object" && typeof Reflect.decorate == "function")
    i = Reflect.decorate(e, t, r, n);
  else
    for (var s = e.length - 1; s >= 0; s--)
      (a = e[s]) && (i = (o < 3 ? a(i) : o > 3 ? a(t, r, i) : a(t, r)) || i);
  return o > 3 && i && Object.defineProperty(t, r, i), i;
}, y = {
  debug: "debug",
  error: "error",
  info: "info",
  warn: "warn"
}, Ze = {
  console: "console",
  http: "http",
  silent: "silent"
}, mi = Object.keys(y), yt = (
  /** @class */
  function() {
    function e(t, r, n, o, i) {
      n === void 0 && (n = Ze.http), o === void 0 && (o = y.debug), i === void 0 && (i = {}), this.handleLogStrategy = t, this.handlerType = n, this.level = o, this.contextManager = Ue(
        3
        /* CustomerDataType.LoggerContext */
      ), this.contextManager.setContext(i), r && this.contextManager.setContextProperty("logger", { name: r });
    }
    return e.prototype.log = function(t, r, n, o) {
      n === void 0 && (n = y.info);
      var i;
      if (o != null) {
        var a = o instanceof Error ? N(o) : void 0, s = Xt({
          stackTrace: a,
          originalError: o,
          nonErrorPrefix: "Provided",
          source: R.LOGGER,
          handling: "handled",
          startClocks: ne()
        });
        i = {
          stack: s.stack,
          kind: s.type,
          message: s.message
        };
      }
      var c = U(r), u = i ? q({ error: i }, c) : c;
      this.handleLogStrategy({
        message: U(t),
        context: u,
        status: n
      }, this);
    }, e.prototype.debug = function(t, r, n) {
      this.log(t, r, y.debug, n);
    }, e.prototype.info = function(t, r, n) {
      this.log(t, r, y.info, n);
    }, e.prototype.warn = function(t, r, n) {
      this.log(t, r, y.warn, n);
    }, e.prototype.error = function(t, r, n) {
      this.log(t, r, y.error, n);
    }, e.prototype.setContext = function(t) {
      this.contextManager.setContext(t);
    }, e.prototype.getContext = function() {
      return this.contextManager.getContext();
    }, e.prototype.setContextProperty = function(t, r) {
      this.contextManager.setContextProperty(t, r);
    }, e.prototype.removeContextProperty = function(t) {
      this.contextManager.removeContextProperty(t);
    }, e.prototype.clearContext = function() {
      this.contextManager.clearContext();
    }, e.prototype.setHandler = function(t) {
      this.handlerType = t;
    }, e.prototype.getHandler = function() {
      return this.handlerType;
    }, e.prototype.setLevel = function(t) {
      this.level = t;
    }, e.prototype.getLevel = function() {
      return this.level;
    }, bi([
      Ir
    ], e.prototype, "log", null), e;
  }()
), St = "logs";
function yi(e) {
  var t = !1, r = Ue(
    2
    /* CustomerDataType.GlobalContext */
  ), n = Ue(
    1
    /* CustomerDataType.User */
  ), o = {}, i = function() {
  }, a = new ei(), s = function(l, d, p, m) {
    p === void 0 && (p = Je(f())), m === void 0 && (m = M()), a.add(function() {
      return s(l, d, p, m);
    });
  }, c = function() {
  }, u = new yt(function() {
    for (var l = [], d = 0; d < arguments.length; d++)
      l[d] = arguments[d];
    return s.apply(void 0, l);
  });
  function f() {
    return {
      view: {
        referrer: document.referrer,
        url: window.location.href
      },
      context: r.getContext(),
      user: n.getContext()
    };
  }
  return $n({
    logger: u,
    init: b(function(l) {
      var d;
      if (!l) {
        E.error("Missing configuration");
        return;
      }
      if (c = function() {
        return Je(l);
      }, ke() && (l = v(l)), !!h(l)) {
        var p = gi(l);
        if (p) {
          if (l.storeContextsAcrossPages) {
            var m = r.getContext();
            r = ht(
              p,
              St,
              2
              /* CustomerDataType.GlobalContext */
            ), r.setContext(q(r.getContext(), m));
            var _ = n.getContext();
            n = ht(
              p,
              St,
              1
              /* CustomerDataType.User */
            ), n.setContext(q(n.getContext(), _));
          }
          d = e(l, p, f), s = d.handleLog, i = d.getInternalContext, a.drain(), t = !0;
        }
      }
    }),
    getGlobalContext: b(function() {
      return r.getContext();
    }),
    setGlobalContext: b(function(l) {
      return r.setContext(l);
    }),
    setGlobalContextProperty: b(function(l, d) {
      return r.setContextProperty(l, d);
    }),
    removeGlobalContextProperty: b(function(l) {
      return r.removeContextProperty(l);
    }),
    clearGlobalContext: b(function() {
      return r.clearContext();
    }),
    createLogger: b(function(l, d) {
      return d === void 0 && (d = {}), o[l] = new yt(function() {
        for (var p = [], m = 0; m < arguments.length; m++)
          p[m] = arguments[m];
        return s.apply(void 0, p);
      }, U(l), d.handler, d.level, U(d.context)), o[l];
    }),
    getLogger: b(function(l) {
      return o[l];
    }),
    getInitConfiguration: b(function() {
      return c();
    }),
    getInternalContext: b(function(l) {
      return i(l);
    }),
    setUser: b(function(l) {
      vi(l) && n.setContext(bt(l));
    }),
    getUser: b(function() {
      return n.getContext();
    }),
    setUserProperty: b(function(l, d) {
      var p, m = bt((p = {}, p[l] = d, p))[l];
      n.setContextProperty(l, m);
    }),
    removeUserProperty: b(function(l) {
      return n.removeContextProperty(l);
    }),
    clearUser: b(function() {
      return n.clearContext();
    })
  });
  function v(l) {
    return O({}, l, { clientToken: "empty" });
  }
  function h(l) {
    return t ? (l.silentMultipleInit || E.error("DD_LOGS is already initialized."), !1) : !0;
  }
}
var Si = "logs";
function Ei(e) {
  var t = bo(e, Si, function(r) {
    return Ci(e, r);
  });
  return {
    findTrackedSession: function(r) {
      var n = t.findActiveSession(r);
      return n && n.trackingType === "1" ? {
        id: n.id
      } : void 0;
    },
    expireObservable: t.expireObservable
  };
}
function _i(e) {
  var t = dr(e) === "1", r = t ? {} : void 0;
  return {
    findTrackedSession: function() {
      return r;
    },
    expireObservable: new C()
  };
}
function dr(e) {
  return qe(e.sessionSampleRate) ? "1" : "0";
}
function Ci(e, t) {
  var r = Oi(t) ? t : dr(e);
  return {
    trackingType: r,
    isTracked: r === "1"
  };
}
function Oi(e) {
  return e === "0" || e === "1";
}
function wi(e, t, r, n, o) {
  var i = mi.concat(["custom"]), a = {};
  i.forEach(function(s) {
    a[s] = Go(s, t.eventRateLimiterThreshold, o);
  }), r.subscribe(0, function(s) {
    var c, u, f = s.rawLogsEvent, v = s.messageContext, h = v === void 0 ? void 0 : v, l = s.savedCommonContext, d = l === void 0 ? void 0 : l, p = Sr(f.date), m = e.findTrackedSession(p);
    if (m) {
      var _ = d || n(), L = q({
        service: t.service,
        session_id: m.id,
        // Insert user first to allow overrides from global context
        usr: Ut(_.user) ? void 0 : _.user,
        view: _.view
      }, _.context, Re(p), f, h);
      ((c = t.beforeSend) === null || c === void 0 ? void 0 : c.call(t, L)) === !1 || L.origin !== R.AGENT && ((u = a[L.status]) !== null && u !== void 0 ? u : a.custom).isLimitReached() || r.notify(1, L);
    }
  });
}
var Et = !1;
function Re(e) {
  var t = window;
  if (lr()) {
    var r = n(t.DD_RUM_SYNTHETICS);
    return !r && !Et && (Et = !0, er("Logs sent before RUM is injected by the synthetics worker", {
      testId: li(),
      resultId: di()
    })), r;
  }
  return n(t.DD_RUM);
  function n(o) {
    if (o && o.getInternalContext)
      return o.getInternalContext(e);
  }
}
var D, Ti = (D = {}, D[T.log] = y.info, D[T.debug] = y.debug, D[T.info] = y.info, D[T.warn] = y.warn, D[T.error] = y.error, D);
function Ri(e, t) {
  var r = Xo(e.forwardConsoleLogs).subscribe(function(n) {
    t.notify(0, {
      rawLogsEvent: {
        date: M(),
        message: n.message,
        origin: R.CONSOLE,
        error: n.api === T.error ? {
          stack: n.stack,
          fingerprint: n.fingerprint
        } : void 0,
        status: Ti[n.api]
      }
    });
  });
  return {
    stop: function() {
      r.unsubscribe();
    }
  };
}
var ce, Li = (ce = {}, ce[B.cspViolation] = y.error, ce[B.intervention] = y.error, ce[B.deprecation] = y.warn, ce);
function xi(e, t) {
  var r = Vn(e, e.forwardReports).subscribe(function(n) {
    var o = n.message, i = Li[n.type], a;
    i === y.error ? a = {
      kind: n.subtype,
      stack: n.stack
    } : n.stack && (o += " Found in ".concat(zn(n.stack))), t.notify(0, {
      rawLogsEvent: {
        date: M(),
        message: o,
        origin: R.REPORT,
        error: a,
        status: i
      }
    });
  });
  return {
    stop: function() {
      r.unsubscribe();
    }
  };
}
function Ii(e, t) {
  if (!e.forwardErrorsToLogs)
    return { stop: me };
  var r = Do(e).subscribe(function(i) {
    i.state === "complete" && o("xhr", i);
  }), n = qo().subscribe(function(i) {
    i.state === "resolve" && o("fetch", i);
  });
  function o(i, a) {
    !e.isIntakeUrl(a.url) && (Ni(a) || rr(a.status)) && ("xhr" in a ? Ai(a.xhr, e, s) : a.response ? Ui(a.response, e, s) : a.error && ki(a.error, e, s));
    function s(c) {
      t.notify(0, {
        rawLogsEvent: {
          message: "".concat(Bi(i), " error ").concat(a.method, " ").concat(a.url),
          date: a.startClocks.timeStamp,
          error: {
            stack: c || "Failed to load"
          },
          http: {
            method: a.method,
            status_code: a.status,
            url: a.url
          },
          status: y.error,
          origin: R.NETWORK
        }
      });
    }
  }
  return {
    stop: function() {
      r.unsubscribe(), n.unsubscribe();
    }
  };
}
function Ai(e, t, r) {
  typeof e.response == "string" ? r(ct(e.response, t)) : r(e.response);
}
function ki(e, t, r) {
  r(ct(X(N(e)), t));
}
function Ui(e, t, r) {
  var n = So(e);
  !n || !n.body ? r() : window.TextDecoder ? Pi(n.body, t.requestErrorResponseLengthLimit, function(o, i) {
    r(o ? "Unable to retrieve response: ".concat(o) : i);
  }) : n.text().then(b(function(o) {
    return r(ct(o, t));
  }), b(function(o) {
    return r("Unable to retrieve response: ".concat(o));
  }));
}
function Ni(e) {
  return e.status === 0 && e.responseType !== "opaque";
}
function ct(e, t) {
  return e.length > t.requestErrorResponseLengthLimit ? "".concat(e.substring(0, t.requestErrorResponseLengthLimit), "...") : e;
}
function Bi(e) {
  return e === "xhr" ? "XHR" : "Fetch";
}
function Pi(e, t, r) {
  si(e, function(n, o, i) {
    if (n)
      r(n);
    else {
      var a = new TextDecoder().decode(o);
      i && (a += "..."), r(void 0, a);
    }
  }, {
    bytesLimit: t,
    collectStreamBody: !0
  });
}
function Mi(e, t) {
  if (!e.forwardErrorsToLogs)
    return { stop: me };
  var r = new C(), n = Wn(r).stop, o = r.subscribe(function(i) {
    t.notify(0, {
      rawLogsEvent: {
        message: i.message,
        date: i.startClocks.timeStamp,
        error: {
          kind: i.type,
          stack: i.stack
        },
        origin: R.SOURCE,
        status: y.error
      }
    });
  });
  return {
    stop: function() {
      n(), o.unsubscribe();
    }
  };
}
var Fi = Fo, Z, _t = (Z = {}, Z[y.debug] = 0, Z[y.info] = 1, Z[y.warn] = 2, Z[y.error] = 3, Z);
function Gi(e) {
  function t(r, n, o, i) {
    var a = q(n.getContext(), r.context);
    Ct(r.status, Ze.console, n) && Di(r, a), Ct(r.status, Ze.http, n) && e.notify(0, {
      rawLogsEvent: {
        date: i || M(),
        message: r.message,
        status: r.status,
        origin: R.LOGGER
      },
      messageContext: a,
      savedCommonContext: o
    });
  }
  return {
    handleLog: t
  };
}
function Ct(e, t, r) {
  var n = r.getHandler(), o = Array.isArray(n) ? n : [n];
  return _t[e] >= _t[r.getLevel()] && oe(o, t);
}
function Di(e, t) {
  H[e.status].call(A, e.message, t);
}
function Hi(e, t, r, n, o) {
  var i = fr(e, {
    endpoint: e.logsEndpointBuilder
  }, e.replica && {
    endpoint: e.replica.logsEndpointBuilder
  }, r, n, o);
  return t.subscribe(1, function(a) {
    i.add(a);
  }), i;
}
function ji(e) {
  var t = st();
  e.subscribe(1, function(r) {
    t.send("log", r);
  });
}
function Yi(e) {
  return {
    get: function(t) {
      var r = e.findTrackedSession(t);
      if (r)
        return {
          session_id: r.id
        };
    }
  };
}
function zi(e, t, r) {
  var n = new Fi(), o = [];
  n.subscribe(1, function(d) {
    return Qt("logs", d);
  });
  var i = function(d) {
    n.notify(0, {
      rawLogsEvent: {
        message: d.message,
        date: d.startClocks.timeStamp,
        origin: R.AGENT,
        status: y.error
      }
    }), er("Error reported to customer", { "error.message": d.message });
  }, a = Uo(t), s = t.sessionStoreStrategyType && !ke() && !lr() ? Ei(t) : _i(t), c = qi(t, i, a, s.expireObservable), u = c.telemetry, f = c.stop;
  o.push(function() {
    return f();
  }), u.setContextProvider(function() {
    var d, p, m, _, L, se;
    return {
      application: {
        id: (d = Re()) === null || d === void 0 ? void 0 : d.application_id
      },
      session: {
        id: (p = s.findTrackedSession()) === null || p === void 0 ? void 0 : p.id
      },
      view: {
        id: (_ = (m = Re()) === null || m === void 0 ? void 0 : m.view) === null || _ === void 0 ? void 0 : _.id
      },
      action: {
        id: (se = (L = Re()) === null || L === void 0 ? void 0 : L.user_action) === null || se === void 0 ? void 0 : se.id
      }
    };
  }), Ii(t, n), Mi(t, n), Ri(t, n), xi(t, n);
  var v = Gi(n).handleLog;
  if (wi(s, t, n, r, i), ke())
    ji(n);
  else {
    var h = Hi(t, n, i, a, s.expireObservable).stop;
    o.push(function() {
      return h();
    });
  }
  co(hi(e));
  var l = Yi(s);
  return {
    handleLog: v,
    getInternalContext: l.get,
    stop: function() {
      o.forEach(function(d) {
        return d();
      });
    }
  };
}
function qi(e, t, r, n) {
  var o = ao("browser-logs-sdk", e), i = [];
  if (ke()) {
    var a = st(), s = o.observable.subscribe(function(f) {
      return a.send("internal_telemetry", f);
    });
    i.push(function() {
      return s.unsubscribe();
    });
  } else {
    var c = fr(e, {
      endpoint: e.rumEndpointBuilder
    }, e.replica && {
      endpoint: e.replica.rumEndpointBuilder
    }, t, r, n);
    i.push(function() {
      return c.stop();
    });
    var u = o.observable.subscribe(function(f) {
      return c.add(f, uo(e));
    });
    i.push(function() {
      return u.unsubscribe();
    });
  }
  return {
    telemetry: o,
    stop: function() {
      i.forEach(function(f) {
        return f();
      });
    }
  };
}
var j = yi(zi);
Zn(F(), "DD_LOGS", j);
function x() {
  return (new Error("stack").stack ?? "").split(/\r?\n/).slice(1).flatMap((t) => /\/packages\/logger/.test(t) ? [] : [t.trim().slice(3)]).join(`
`);
}
var Ot, wt, Tt;
const Y = (
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore --
  ((wt = (Ot = import.meta) == null ? void 0 : Ot.env) == null ? void 0 : wt.VITE_DATADOG_CLIENT_TOKEN) ?? (typeof process < "u" ? (Tt = process.env) == null ? void 0 : Tt.NEXT_DATADOG_CLIENT_TOKEN : "")
);
Y && j.init({
  clientToken: Y,
  service: "checkout-form",
  env: typeof process < "u" ? process.env.NODE_ENV : "prod",
  forwardConsoleLogs: "all",
  forwardReports: "all",
  site: "datadoghq.eu	",
  forwardErrorsToLogs: !0,
  sessionSampleRate: 100
});
let w = {};
const Ki = {
  addDefaultAttributes: (e) => {
    w = { ...w, ...e };
  },
  info: (e, t) => {
    if (Y) {
      j.logger.info(e, { ...t, ...w, stack: x() });
      return;
    }
    console.info(e, { ...t, ...w, stack: x() });
  },
  debug: (e, t) => {
    if (Y) {
      j.logger.debug(e, { ...t, ...w, stack: x() });
      return;
    }
    console.debug(e, { ...t, ...w, stack: x() });
  },
  trace: (e, t) => {
    if (Y) {
      j.logger.debug(e, { ...t, ...w, stack: x() });
      return;
    }
    console.trace(e, { ...t, ...w, stack: x() });
  },
  warn: (e, t) => {
    if (Y) {
      j.logger.warn(e, { ...t, ...w, stack: x() });
      return;
    }
    console.warn(e, { ...t, ...w, stack: x() });
  },
  error: (e, t) => {
    if (Y) {
      j.logger.error(e, { ...t, ...w, stack: x() });
      return;
    }
    console.error(e, { ...t, ...w, stack: x() });
  }
};
export {
  Ki as logger
};
